import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { Button, Form, FormGroup, Input, Label, Alert } from 'reactstrap';
import { api } from '../utils/api';

import { Content } from '../components/Layout';
import Swal from 'sweetalert2'
import { Redirect } from "react-router-dom";
import { Col, Row, Card, CardBody } from 'reactstrap';

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(['valsys']);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.localStorage.getItem('valsys')) {
      dispatch({
        type: "LOGIN",
        jwt: JSON.parse(window.localStorage.getItem('valsys')) || null,
        user: JSON.parse(window.localStorage.getItem('profile')) || null,
      })
    }
    props.hideLoader();
  }, [window.localStorage.getItem('valsys')]);


  async function handleSubmit(event) {
    setError("");
    if (username === "") {
      setError("Please fill in username");
    }
    if (password === "") {
      setError("Please fill in password");
    }
    if (username !== "" && password !== "") {
      props.showLoader();
      await fetch(api.login, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: 'username=' + username + '&password=' + password
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson)
          props.hideLoader();
          if (responseJson.status === 'success') {
            dispatch({
              type: "LOGIN",
              user: responseJson.result.user,
              jwt: responseJson.result.user.token
            })
            // setCookie('valsys', JSON.stringify(responseJson.token), { maxAge: 3600 });
            localStorage.setItem('valsys', JSON.stringify(responseJson.result.user.token));
            localStorage.setItem('profile', JSON.stringify(responseJson.result.user));
            window.location.reload(false);
          } else {
            let error = "";
            responseJson.error.forEach((item, index) => {
              error += item.message + ".\n";
            })
            Swal.fire('',  error, 'error');
          }

        }).catch(error => {
          console.error(error);
        });
    }
  };

  if (localStorage.getItem('valsys')) {
    return <Redirect to="/" />;
  } else {
    return (
      <main className="cr-app bg-dark">
        <Content fluid>
          <Row className="justify-content-center align-items-center d-flex h-100">
            <Col md={6} lg={4}>
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <div className="text-center pb-4">
                      <h2 className="font-weight-bold">Valsys</h2>
                    </div>
                    <FormGroup>
                      <Label for={"username"}>Username</Label>
                      <Input type="text" placeholder='' value={username} onChange={(e) => setUsername(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                      <Label for={"password"}>Password</Label>
                      <Input type="password" placeholder='' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </FormGroup>
                    <Alert color="danger" isOpen={error !== "" ? true : false}>{error}</Alert>

                    <hr />
                    <Button
                      color="primary"
                      block
                      onClick={handleSubmit}>
                      Login
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Content>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    user: auth.user,
    jwt: auth.jwt
  }
}

export default connect(mapStateToProps)(Login);

