import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch } from 'react-redux';
export function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

export function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export function parseFloatAndFormat(number = 0, decimal = -1, ifNaN = '') {
    try {
        if (isNaN(parseFloat(number))) {
            return number;
        }
        var output = parseFloat(number || 0);

        if (decimal >= 0) {
            output = parseFloat(output).toFixed(decimal)
        }

        if (output == 'NaN') {
            output = ifNaN;
        }

        return output
    } catch (error) {
        console.log('parsing error', number)
        return number
    }
}

export function msgAlert(title, msg, lang) {
    return (
        confirmAlert({
            title: (title.charAt(0).toUpperCase() + title.slice(1)),
            message: (Array.isArray(msg) ? msg.join(". ") : msg),
            buttons: [
                { label: "Ok", onClick: () => console.log('OK Pressed') },
            ],
            closeOnEscape: true,
            closeOnClickOutside: false,
            overlayClassName: "react-confirm-alert-overlay"
        })
    );
}

export function getAccess(page, action) {
    let userProfile = localStorage.getItem('profile');
    let access = false;
    let UserAccess;


    try {
        if (userProfile) {
            userProfile = JSON.parse(userProfile);

            if (userProfile.rights) {
                UserAccess = userProfile.rights;
            }
        }
    } catch (error) {
        console.log('User rights not found')
    }

    let hasKey = false;
    if (UserAccess) {
        hasKey = UserAccess.hasOwnProperty(page);
        if (UserAccess.hasOwnProperty(page)) {
            if (UserAccess[page].includes(action)) {
                access = true;
            } else {
                access = false;
            }
        }
        // for (let key in UserAccess){
        //     if(key === page){
        //         if(UserAccess[key].includes(action)){
        //             access = true;
        //         }else{
        //             access = false;
        //         }
        //     }
        // }
        //     UserAccess.map((item, i)=>{
        //         if(item.hasOwnProperty(page)){
        //             if(item[page].includes(action)){
        //                 access = true;
        //             }else{
        //                 access = false;
        //             }
        //         }else{
        //             return true;
        //         }
        //     })
    } else {
        access = false;
    }
    return access;
}

export function keepLogin(cookie) {
    if (cookie) {

    } else {
        localStorage.removeItem("valsys");
        localStorage.removeItem("profile");
        window.location.reload()
    }
    return true;
}