import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Alert } from 'reactstrap';
import { api } from '../utils/api';
import { Redirect } from "react-router-dom";

class AuthForm extends React.Component {
  state = {
    username: "aaa",
    password: "123",
    error: "",
    loading: false,
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ error: "" });
    if (this.state.username === "") {
      this.setState({ error: "Please fill in username" });
    }
    if (this.state.password === "") {
      this.setState({ error: "Please fill in password" });
    }
    if(this.state.username !=="" && this.state.password !== ""){
      this.setState({ loading: false });
      await this.props.dispatch({
        type: "LOGIN",
        user:  this.state.username ,
        jwt:  this.state.password ,
      });
      console.log(this.props.user);
      // fetch(api.login, {
      //     method: "POST",
      //     headers: new Headers({
      //         "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      //     }),
      //     body: 'username='+ this.state.username + '&password=' + this.state.password
      // })
      // .then(response => response.json())
      // .then(responseJson => {
      //     console.log('login', responseJson);
                    
      //               // if(responseJson.success){
      //               //     this.createDevice(responseJson);
      //               //     this.getProfile(responseJson);
      //               //     // this.getAllChat(responseJson)
      //               // }else{
      //               //     this.setState({ isLoading: false})
      //               //     if(responseJson.code!=undefined){
      //               //         if(translate(this.props.lang.value,  responseJson.code)!=undefined){
      //               //             setTimeout(() => {
      //               //                 Alert.alert(
      //               //                     translate(this.props.lang.value,"fail"),
      //               //                     translate(this.props.lang.value, responseJson.code),
      //               //                     [
      //               //                         {text: translate(this.props.lang.value,"ok"), onPress: () => console.log('OK Pressed')},
      //               //                     ],
      //               //                     {cancelable: false},
      //               //                 )
      //               //             }, 500);
      //               //         }else{
      //               //             setTimeout(() => {
      //               //                 Alert.alert(
      //               //                     translate(this.props.lang.value,"fail"),
      //               //                     translate(this.props.lang.value, "login fail"),
      //               //                     [
      //               //                         {text: translate(this.props.lang.value,"ok"), onPress: () => console.log('OK Pressed')},
      //               //                     ],
      //               //                     {cancelable: false},
      //               //                 )
      //               //             }, 500);
      //               //         }
      //               //     }else{
      //               //         let error = '';
      //               //         responseJson.errors.map((item,index)=>{
      //               //             error += item.field + ' : ' + item.message + ".\n"
      //               //         })
      //               //         setTimeout(() => {
      //               //             Alert.alert(
      //               //                 translate(this.props.lang.value,"fail"),
      //               //                 error,
      //               //                 [
      //               //                     {text: translate(this.props.lang.value,"ok"), onPress: () => console.log('OK Pressed')},
      //               //                 ],
      //               //                 {cancelable: false},
      //               //             )
      //               //         }, 500);
      //               //     }
      //               // }
                    
      // })
      // .catch(error => {
      //     this.setState({ isLoading: false})
      //     console.error(error);
      // });
      
      // localStorage.setItem("valsys", "token");
      // window.location.reload(false);
      // fetch(api.login, {
      //   method: "POST",
      //   mode: 'no-cors',
      //   headers: new Headers({
      //       "Content-Type": "application/x-www-form-urlencoded", 
      //   }),
      //   body: 'username='+ this.state.username + '&password=' + this.state.password
      // })
      // .then(response => response.json())
      // .then(responseJson => {
      //   console.log(responseJson)
      //   if(responseJson.status == "success"){
      //     alert('login success')
      //   }else{
      //     alert('login fail')
      //   }
      // }).catch(error => {
      //   console.error(error);
      // });
    }
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    if (localStorage.getItem("valsys")) {
      return <Redirect to="/" />;
    }else{
    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input {...usernameInputProps} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input {...passwordInputProps} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
        </FormGroup>
        <Alert color="danger" isOpen={this.state.error !== "" ? true : false}>{this.state.error}</Alert>

        {/* <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup> */}
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          disabled={this.state.loading}
          onClick={this.handleSubmit}>
          Login
        </Button>

        {/* <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div> */}

        {children}
      </Form>
    );

    }
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Enter password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => { },
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
      lang: i18n.lang,
      user: auth.user,
      jwt: auth.jwt,
  }
}

export default connect(mapStateToProps)(AuthForm);

