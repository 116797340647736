import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
// import { CookiesProvider } from "react-cookie";
import store from './redux/store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const showLoader = () => {
    console.log('hideLoader from app')
    // console.log('loader', loader)
    document.getElementById('preloader').classList.remove('loader--hide');
}

const hideLoader = () => {
    console.log('hideLoader from app')
    // console.log('loader', loader)
    document.getElementById('preloader').classList.add('loader--hide');
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        {/* <CookiesProvider> */}
        <div id="preloader">
            <div className="loader"></div>
        </div>
        <App
            hideLoader={hideLoader}
            showLoader={showLoader}
        />
        {/* </CookiesProvider> */}
    </Provider>
);

// ReactDOM.render(
//     <Provider store={store}>
//         {/* <CookiesProvider> */}
//         <div id="preloader">
//             <div className="loader"></div>
//         </div>
//         <App
//             hideLoader={hideLoader}
//             showLoader={showLoader}
//         />
//         {/* </CookiesProvider> */}
//     </Provider>
//     , document.getElementById('root'));
