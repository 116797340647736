import { LOGIN, LOGOUT, UPDATE_TOKEN, UPDATE_USER } from "./action";

const defaultState = {
    jwt: null,
    user: null,
};

const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                ...{
                    user: action.user,
                    jwt: action.jwt,
                }
            }
        case LOGOUT:
            return {
                ...state,
                user: null,
                jwt: null
            }
        case UPDATE_TOKEN:
            return {
                ...state,
                ...{
                    jwt: action.jwt
                }
            }
        case UPDATE_USER:
            return {
                ...state,
                ...{
                    jwt: action.jwt,
                    user: action.user,
                }
            }
        default:
            return state
    }
}

export default authReducer;