// const url = 'https://valsysapi.com';
const url = 'https://valsys-api.bizteg.com';
// const url = 'http://127.0.0.1:3001';
const version = '1.0';
const api = {
    login: url + '/login',
    createNewUser: url + '/register',
    editUser: url + '/editUser/:id',
    getusers: url + '/getusers',
    getuseraccess: url + '/getuseraccess',
    edituseraccess: url + '/edituseraccess',
    getDashboard: url + '/getDashboard',
    getUserById: url + '/getUser',
    getAllMarket: url + '/getAllMarket',
    getSectorsAndQualities: url + '/getSectorsAndQualities',
    getPeriods: url + '/getPeriods',
    getAreas: url + '/getAreas',
    getAllCountries: url + '/getAllCountries',
    getCitiesOfCountry: url + '/getCitiesOfCountry',
    getAllTickers: url + '/getAllTickers',
    getAllPNLTickers: url + '/getAllPNLTickers',
    getAllBSTickers: url + '/getAllBSTickers',
    getCCSQformula: url + '/getCCSQformula',
    getPreviousCCSQ: url + '/getPreviousCCSQ',
    newSector: url + '/newSector',
    getSector: url + '/getSector',
    getSectorById: url + '/getSectorById',
    editSector: url + '/editSector',
    deleteSectorById: url + '/deleteSectorById',
    getSectorApproval: url + '/getSectorApproval',
    approveSector: url + '/approveSector',
    rejectSector: url + '/rejectSector',
    checkSectorData: url + '/checkSectorData',
    checkExchangeRate: url + '/checkExchangeRate',
    getCurrencies: url + '/getCurrencies',
    getScales: url + '/getScales',
    newBalanceSheet: url + '/newbalancesheet',
    approveBalanceSheet: url + '/approveBalanceSheet',
    rejectBalanceSheet: url + '/rejectBalanceSheet',
    getBalanceSheetApproval: url + '/getBalanceSheetApproval',
    getBalanceSheetById: url + '/getBalanceSheetById',
    getBalanceSheetApprovalById: url + '/getBalanceSheetApprovalById',
    getBalanceSheet: url + '/getBalanceSheet',
    getNTA: url + '/getNTA',
    editBalanceSheet: url + '/editBalanceSheet',
    deleteBalanceSheetById: url + '/deleteBalanceSheetById',
    newCompany: url + '/newCompany',
    getCompanyById: url + '/getCompanyById',
    deleteCompanyById: url + '/deleteCompanyById',
    editCompany: url + '/editCompany',
    newProfitAndLoss: url + '/newProfitAndLoss',
    checkProfitAndLossOverlapData: url + '/checkProfitAndLossOverlapData',
    approveProfitAndLoss: url + '/approveProfitAndLoss',
    deleteProfitAndLossById: url + '/deleteProfitAndLossById',
    rejectProfitAndLoss: url + '/rejectProfitAndLoss',
    getProfitAndLossApproval: url + '/getProfitAndLossApproval',
    getProfitAndLossApprovalById: url + '/getProfitAndLossApprovalById',
    getProfitAndLossById: url + '/getProfitAndLossById',
    getProfitAndLoss: url + '/getProfitAndLoss',
    newNAVFV: url + '/newNAVFV',
    newNAVFVversiontwo: url + '/newNAVFVversiontwo',
    getNAVFVApproval: url + '/getNAVFVApproval',
    getNAVFVById: url + '/getNAVFVById',
    approveNAVFV: url + '/approveNAVFV',
    rejectNAVFV: url + '/rejectNAVFV',
    getNAVFV: url + '/getNAVFV',
    deleteNAVFVById: url + '/deleteNAVFVById',
    getNOI: url + '/getNOI',
    getnoofshares: url + '/getnoofshares',
    apReits: url + '/apReits',
    uploadfile: url + '/uploadfile',
    uploadfilepageload: url + '/uploadfilepageload',
    getPortfolio: url + '/getPortfolio',
    gettradesportfolio: url + '/gettradesportfolio',
    getperformancetracking: url + '/getperformancetracking',
    preparetradesportfolio: url + '/preparetradesportfolio',
    gettradeslogs: url + '/gettradeslogs',
    calculatepercentages: url + '/calculatePercentages',
    createPortfolio: url + '/createPortfolio',
    inserttoport: url + '/inserttoport',
    orderport: url + '/orderPort',
    gettradesportfoliologs: url + '/gettradesportfoliologs',
    updateoutstandingorder: url + '/updateOutstandingOrder',
    tradesprint: url + '/trades/print',
    performancetrackingprint: url + '/performancetracking/print',
    deleteUser: url + '/deleteUser',
    getmarketdata: url + '/getmarketdata',
    newmarketdata: url + '/newmarketdata',
    deletemarketdatabyid: url + '/deletemarketdatabyid',
}

export { api, url, version };