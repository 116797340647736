import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React, { useCallback, useEffect } from 'react';
import componentQueries from 'react-component-queries';
import { useCookies } from "react-cookie";
import { useDispatch, connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
// import DashboardPage from './pages/DashboardPage';
import PrivateRoute from './route/private-route';
import PublicRoute from './route/public-route';
import ProtectedRoute from './route/protected-route';
import './styles/reduction.scss';
import './styles/custom.css';

// import PortfolioTradesPage from './pages/PortfolioTrades';

// const AlertPage = React.lazy(() => import('pages/AlertPage'));
// const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
// const BadgePage = React.lazy(() => import('pages/BadgePage'));
// const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
// const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
// const CardPage = React.lazy(() => import('pages/CardPage'));
// const ChartPage = React.lazy(() => import('pages/ChartPage'));
// const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
// const FormPage = React.lazy(() => import('pages/FormPage'));
// const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
// const ModalPage = React.lazy(() => import('pages/ModalPage'));
// const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
// const TablePage = React.lazy(() => import('pages/TablePage'));
// const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
// const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

const DashboardPage = React.lazy(() => import('pages/DashboardPage'));

const SimulationPage = React.lazy(() => import('pages/Simulation'));
const ApprovalPage = React.lazy(() => import('pages/Approval'));
// const MarketNewPage = React.lazy(() => import('pages/MarketNew'));
const MarketNewPage = React.lazy(() => import('pages/MarketNew'));
const MarketListPage = React.lazy(() => import('pages/MarketList'));

const NAVFVNewPage = React.lazy(() => import('pages/NAVFVNew'));
const NAVFVListPage = React.lazy(() => import('pages/NAVFVList'));
const NAVFVApprovalPage = React.lazy(() => import('pages/NAVFVApproval'));
const NAVFVImportPage = React.lazy(() => import('pages/NAVFVImport'));

const ProfitLossApproval = React.lazy(() => import('pages/ProfitLossApproval'));
const ProfitLossApprovalDetail = React.lazy(() => import('pages/ProfitLossApprovalDetail'));
const ProfitLossNew = React.lazy(() => import('pages/ProfitLossNew'));
const ProfitLossList = React.lazy(() => import('pages/ProfitLossList'));

const BalanceSheetNew = React.lazy(() => import('pages/BalanceSheetNew'));
const BalanceSheetList = React.lazy(() => import('pages/BalanceSheetList'));
const BalanceSheetApproval = React.lazy(() => import('pages/BalanceSheetApproval'));
const BalanceSheetApprovalDetail = React.lazy(() => import('pages/BalanceSheetApprovalDetail'));

const SectorNewPage = React.lazy(() => import('pages/SectorNew'));
const SectorListPage = React.lazy(() => import('pages/SectorList'));
const SectorApprovalPage = React.lazy(() => import('pages/SectorApproval'));
const SectorApprovalDetailPage = React.lazy(() => import('pages/SectorApprovalDetail'));

const CompanyNewPage = React.lazy(() => import('pages/CompanyNew'));
const CompanyListPage = React.lazy(() => import('pages/CompanyList'));

const ValuationPage = React.lazy(() => import('pages/Valuation'));
const PortfolioPage = React.lazy(() => import('pages/Portfolio'));
const PortfolioPerformanceTrackingPage = React.lazy(() => import('pages/PortfolioPerformanceTracking'));

const PortfolioTradesPage = React.lazy(() => import('pages/PortfolioTrades'));
const PortfolioTradesLogPage = React.lazy(() => import('pages/PortfolioTradesLog'));

const ReportPage = React.lazy(() => import('pages/Reports'));

const UserNewPage = React.lazy(() => import('pages/UserNew'));
const UserListPage = React.lazy(() => import('pages/UserList'));
const UserAccessListPage = React.lazy(() => import('pages/UserAccessList'));
const UserAccessEditPage = React.lazy(() => import('pages/UserAccessEdit'));
const UserEditPage = React.lazy(() => import('pages/UserEdit'));

const PortfolioUploadFile = React.lazy(() => import('pages/UploadFile'));
const SyncMarketDataPage = React.lazy(() => import('pages/SyncMarketData'));

// const NewUserPage = React.lazy(() => import('pages/NewUser'));
// const UsersListPage = React.lazy(() => import('pages/UsersList'));
// const UsersAccessPage = React.lazy(() => import('pages/UsersAccess'));
// const EditUsersAccessPage = React.lazy(() => import('pages/EditUsersAccess'));
// const EditUserPage = React.lazy(() => import('pages/EditUser'));
// const NewSectorEntry = React.lazy(() => import('pages/NewSectorEntry'));
// const SectorPendingApproval = React.lazy(() => import('pages/SectorPendingApproval'));
// const SectorList = React.lazy(() => import('pages/SectorList'));
// const SectorListNavFv = React.lazy(() => import('pages/SectorListNavFv'));
// const BalanceSheetNew = React.lazy(() => import('pages/BalanceSheetNew'));
// const BalanceSheetApproval = React.lazy(() => import('pages/BalanceSheetApproval'));
// const ProfitLossNew = React.lazy(() => import('pages/ProfitLossNew'));
// const ProfitLossApproval = React.lazy(() => import('pages/ProfitLossApproval'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};
function App(props) {
  console.log('App props', props)
  const dispatch = useDispatch();
  // const [cookies] = useCookies(['valsys']);
  useEffect(() => {
    if (localStorage.getItem('valsys')) {
      dispatch({
        type: "LOGIN",
        jwt: localStorage.getItem('valsys') || null,
        user: JSON.parse(localStorage.getItem('profile')) || null,
      })
    } else {
      localStorage.removeItem('valsys');
      localStorage.removeItem('profile');
    }
  }, [localStorage.getItem('valsys')]);

  console.log('authuser', JSON.parse(localStorage.getItem('profile')))

  return (
    <BrowserRouter basename={getBasename()}>
      {/* <GAListener> */}
      <Switch>
        {/* <LayoutRoute
          exact
          path="/login"
          layout={EmptyLayout}
          component={props => (
            <Login/>
          )}
        /> */}
        {/* <Route exact component={<LoginPage/>} path="/login" /> */}
        {/* <LayoutRoute
          exact
          path="/signup"
          layout={EmptyLayout}
          component={props => (
            <AuthPage {...props} authState={STATE_SIGNUP} />
          )}
        /> */}
        <PublicRoute path='/login' exact component={() => <Login {...props} />} />
        <MainLayout breakpoint={props.breakpoint}>
          <React.Suspense fallback={<PageSpinner />}>
            {/* <Route exact path="/" component={DashboardPage} />
            <Route exact path="/market" component={MarketPage} />
            <Route exact path="/sector" component={SectorPage} />
            <Route exact path="/company" component={CompanyPage} />
            <Route exact path="/valuation" component={ValuationPage} />
            <Route exact path="/portfolio" component={PortfolioPage} />
            <Route exact path="/reports" component={ReportPage} />
            <Route exact path="/new-user" component={NewUserPage} />
            <Route exact path="/users-list" component={UsersListPage} />
            <Route exact path="/users-access" component={UsersAccessPage} />
            <Route exact path="/edit-users-access" component={EditUsersAccessPage} /> */}

            <Route path="/" exact component={() => <DashboardPage {...props} />} />

            <Route path="/sector/new" component={() => <SectorNewPage {...props} />} />
            <Route path="/sector/list" component={() => <SectorListPage {...props} />} />
            <Route path="/sector/approval" component={() => <SectorApprovalPage {...props} />} />
            <Route path="/sector-approval/:id" component={(props2) => <SectorApprovalDetailPage  {...props2} {...props} />} />

            <Route path="/company/new" component={() => <CompanyNewPage {...props} />} />
            <Route path="/company/list" component={() => <CompanyListPage {...props} />} />
            <Route path="/valuation" component={() => <ValuationPage {...props} />} />

            <Route path="/navfv/new" component={() => <NAVFVNewPage {...props} />} />
            <Route path="/navfv/list" component={() => <NAVFVListPage {...props} />} />
            <Route path="/navfv/approval" component={() => <NAVFVApprovalPage {...props} />} />
            {/* <Route path="/navfv/import" component={() => <NAVFVImportPage {...props} />} /> */}

            <Route path="/balancesheet/new" component={() => <BalanceSheetNew {...props} />} />
            <Route path="/balancesheet/list" component={() => <BalanceSheetList {...props} />} />
            <Route path="/balancesheet/approval" component={() => <BalanceSheetApproval {...props} />} />
            <Route path="/balancesheet/approvaldetail" component={() => <BalanceSheetApprovalDetail {...props} />} />

            <Route path="/profitloss/new" component={() => <ProfitLossNew {...props} />} />
            <Route path="/profitloss/list" component={() => <ProfitLossList {...props} />} />
            <Route path="/profitloss/approval" component={() => <ProfitLossApproval {...props} />} />
            <Route path="/profitloss/approvaldetail" component={() => <ProfitLossApprovalDetail {...props} />} />

            <Route path="/user/new" component={() => <UserNewPage {...props} />} />
            <Route path="/user/list" component={() => <UserListPage {...props} />} />
            <Route path="/user/edit/:username" component={(props2) => <UserEditPage  {...props2} {...props} />} />
            <Route path="/user/access/list" component={() => <UserAccessListPage {...props} />} />
            <Route path="/user/access/edit/:username" component={(props2) => <UserAccessEditPage {...props2} {...props} />} />

            <Route exact path="/portfolio" component={() => <PortfolioPage {...props} />} />
            <Route exact path="/performance-tracking" component={() => <PortfolioPerformanceTrackingPage {...props} />} />
            <Route exact path="/portfolio-trades" component={() => <PortfolioTradesPage {...props} />} />
            <Route exact path="/portfolio-trades-log" component={() => <PortfolioTradesLogPage {...props} />} />
            <Route exact path="/portfolio/upload-file" component={() => <PortfolioUploadFile {...props} />} />
            <Route exact path="/portfolio/report" component={() => <ReportPage {...props} />} />
            <Route path="/syncmarketdata" component={() => <SyncMarketDataPage {...props} />} />
          </React.Suspense>
        </MainLayout>
        <Redirect to="/" />
      </Switch>
      {/* </GAListener> */}
    </BrowserRouter>
  );
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    user: auth.user
  }
}

// export default connect(mapStateToProps)(componentQueries(query)(App));
export default connect(mapStateToProps)(App);