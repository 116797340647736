import React from 'react';
import _islocalhost from '../registerServiceWorker';

const SourceLink = props => {
  /* eslint-disable jsx-a11y/anchor-has-content */
  var islocalhost = window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )

  console.log('islocalhost', islocalhost)
  return (
    <a href={islocalhost ? process.env.REACT_APP_SOURCE_URL : process.env.websiteURL} rel="noopener noreferrer" {...props} />
  );
};

export default SourceLink;
