import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';


const Footer = () => {
  return (
    <div style={{ borderTop: '1px solid', borderTopColor: '#dddddd', marginTop: 50, alignItems: 'center' }}>
      <Navbar>
        <Nav navbar>
          <NavItem>
            Valsys. {new Date().getFullYear()}
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

export default Footer;
