import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import { useCookies } from 'react-cookie';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);


function Header(props) {
  var userprofile;

  // console.log('1111', localStorage.getItem('profile'))
  // console.log('2222', localStorage.getItem('valsys'))

  try {
    userprofile = localStorage.getItem('profile')
    userprofile = JSON.parse(userprofile)
  } catch (error) {
    console.log('error', error)
  }

  const [isOpenNotificationPopover, setIsOpenNotificationPopover] = useState(false);
  const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
  const [cookies, setCookie] = useCookies(['valsys']);

  function toggleNotificationPopover() {
    setIsOpenNotificationPopover(!isOpenNotificationPopover);
    if (!isNotificationConfirmed) {
      setIsNotificationConfirmed(true)
    }
  }

  function toggleUserCardPopover() {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  }

  function handleSidebarControlButton(event) {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  }

  function signOut() {
    localStorage.removeItem("valsys");
    window.location.reload(false);
    setCookie('valsys', '', { maxAge: 0 });
  };

  if (!localStorage.getItem("valsys")) {
    return (
      <Redirect to="/login" />
    )
  } else {
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button color="dark" outline onClick={handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>
          {/* <SearchInput /> */}
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          {/* <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={isOpenNotificationPopover}
              toggle={toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem> */}

          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={isOpenUserCardPopover}
              toggle={toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-dark">
                <UserCard
                  title={(userprofile && userprofile.username) || '-'}
                  subtitle={(userprofile && userprofile.email) || '-'}
                  text=""
                  className="border-dark"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-dark">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem> */}
                    <ListGroupItem tag="button" action className="border-light" onClick={() => signOut()}>
                      <MdExitToApp /> Sign Out
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    user: auth.user,
  }
}

export default connect(mapStateToProps)(Header);
