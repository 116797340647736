import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { connect } from 'react-redux';
import { FaHome, FaUserAlt, FaFolderOpen, FaStore, FaBuilding, FaHandHoldingUsd, FaFileAlt, FaChartPie, FaUserCircle, FaChevronDown, FaDotCircle, FaReact, FaClipboardCheck, FaChartBar, FaFileInvoice, FaMoneyCheck, FaBriefcase, FaCogs } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { getAccess } from '../../utils/function';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navComponents = [
  // { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
  // {
  //   to: '/button-groups',
  //   name: 'button groups',
  //   exact: false,
  //   Icon: MdGroupWork,
  // },
  // { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
  // { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
  // {
  //   to: '/dropdowns',
  //   name: 'dropdowns',
  //   exact: false,
  //   Icon: MdArrowDropDownCircle,
  // },
  // { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
  // { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
  // { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
  // { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
];

const navContents = [
  // { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  // { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
];

const pageContents = [
  // { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  // {
  //   to: '/login-modal',
  //   name: 'login modal',
  //   exact: false,
  //   Icon: MdViewCarousel,
  // },
];

var sectorManagementContent = [
  { to: '/sector/new', name: 'New Sector', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/sector/list', name: 'List Sectors', exact: false, Icon: FaDotCircle },
  { to: '/sector/approval', name: 'Sectors Approval', exact: false, Icon: FaDotCircle },
];

var marketManagementContent = [
  { to: '/company/new', name: 'New Company', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/company/list', name: 'List Companies', exact: false, Icon: FaDotCircle },
  { to: '/valuation', name: 'Valuation', exact: false, Icon: FaDotCircle },
];

var navfvManagementContent = [
  { to: '/navfv/new', name: 'New entry', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/navfv/list', name: 'List Entries', exact: false, Icon: FaDotCircle },
  { to: '/navfv/approval', name: 'Pending Approval', exact: false, Icon: FaDotCircle },
  // { to: '/navfv/import', name: 'Import NAV & FV', exact: false, Icon: FaDotCircle },
];

var profiltLossContent = [
  { to: '/profitloss/new', name: 'New entry', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/profitloss/list', name: 'List P&L', exact: false, Icon: FaDotCircle },
  { to: '/profitloss/approval', name: 'P&L Approval', exact: false, Icon: FaDotCircle },
];

var balanceSheetContent = [
  { to: '/balancesheet/new', name: 'New entry', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/balancesheet/list', name: 'List Balance Sheet', exact: false, Icon: FaDotCircle },
  { to: '/balancesheet/approval', name: 'Bal. Sheet Approval', exact: false, Icon: FaDotCircle },
];

var companyManagementContent = [
  { to: '/company/new', name: 'New Company', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/company/list', name: 'List Company', exact: false, Icon: FaDotCircle },
];

var usersManagementContent = [
  { to: '/user/new', name: 'New entry', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/user/list', name: 'List Users', exact: false, Icon: FaDotCircle },
  { to: '/user/access/list', name: 'User Access Rights', exact: false, Icon: FaDotCircle },
];

var portfolioContent = [
  { to: '/portfolio', name: 'Portfolio', exact: false, Icon: FaDotCircle },
  { to: '/portfolio/upload-file', name: 'Upload File', exact: false, Icon: FaDotCircle, right: "" },
  { to: '/portfolio-trades', name: 'Trades', exact: false, Icon: FaDotCircle },
  { to: '/performance-tracking', name: 'Performance Tracking', exact: false, Icon: FaDotCircle },
  // { to: '/portfolio/report', name: 'Report', exact: false, Icon: FaDotCircle },
];

var settingsContent = [
  { to: '/syncmarketdata', name: 'Sync Market Data', exact: false, Icon: FaDotCircle }
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenMarket: false,
    isOpenSector: false,
    isOpenCompany: false,
    isOpenUsers: false,
    isOpenProfitLoss: false,
    isOpenPortfolio: false,
    isOpenSettings: false,
    // isOpenContents: true,
    // isOpenPages: true,
  };

  componentDidMount = () => {
    console.log("this.props.user", this.props.user);
    this.setState({
      isOpenMarket: (marketManagementContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenNAVFV: (navfvManagementContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenSector: (sectorManagementContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenCompany: (companyManagementContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenUsers: (usersManagementContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenSettings: (settingsContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenProfitLoss: (profiltLossContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenBalanceSheet: (balanceSheetContent.find((item) => item.to == window.location.pathname)) != undefined,
      isOpenPortfolio: (portfolioContent.find((item) => item.to == window.location.pathname)) != undefined,
    })
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  linkOnClick(to) {
    // console.log('zzz', to, window.location.pathname)
  }

  render() {
    console.log('window.location.pathname', window.location.pathname)
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <div style={{ marginLeft: 8 }}>
              <SourceLink className="navbar-brand d-flex text-white" >
                <span className="">Valsys</span>
              </SourceLink>
            </div>
          </Navbar>
          <Nav vertical>

            {
              // Sector
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Sector')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaChartPie className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Sector</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenSector
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse
              isOpen={this.state.isOpenSector}>
              {sectorManagementContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <div className={'border-bottom'}> </div>

            <div style={{}}>
              <p style={{ paddingLeft: 25, paddingTop: 15, marginBottom: 0, color: '#aba8a8' }}>Company</p>
            </div>
            {
              //Profit & Loss 
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('ProfitLoss')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaMoneyCheck className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Profit &amp; Loss</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenProfitLoss
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenProfitLoss}>
              {profiltLossContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name.replace(/\s/g, '').toLowerCase()}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    onClick={this.linkOnClick(to)}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            { //balance sheet
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('BalanceSheet')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaFileInvoice className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Balance Sheet</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenBalanceSheet
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenBalanceSheet}>
              {balanceSheetContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name.replace(/\s/g, '').toLowerCase()}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    onClick={this.linkOnClick(to)}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <div
              className={'border-bottom'}>
            </div>

            {
              // NAV & FV
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('NAVFV')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaChartBar className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">NAV &amp; FV</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenNAVFV
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>

            <Collapse isOpen={this.state.isOpenNAVFV}>
              {navfvManagementContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name.replace(/\s/g, '').toLowerCase()}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    onClick={this.linkOnClick(to)}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <div className={'border-bottom'}> </div>
            {
              // Market
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Market')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaStore className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Market</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenMarket
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMarket}>
              {marketManagementContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name.replace(/\s/g, '').toLowerCase()}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    onClick={this.linkOnClick(to)}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <div className={'border-bottom'}> </div>
            {
              //portfolio 
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Portfolio')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaBriefcase className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Portfolio</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPortfolio
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPortfolio}>
              {portfolioContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={true}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {
              // Users
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Users')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaUserAlt className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Users</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenUsers
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenUsers}>
              {usersManagementContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {
              // Settings
            }
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Settings')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FaCogs className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Settings</span>
                </div>
                <FaChevronDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenSettings
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenSettings}>
              {settingsContent.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    // className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside >
    );
  }
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    user: auth.user,
    jwt: auth.jwt,
  }
}

export default connect(mapStateToProps, null, null, { pure: false })(Sidebar);